
<template>
  <v-divider
    class="mx-2 my-auto"
    inset
    vertical
    style="height: 16px;"
  />
</template>

<script>
  export default { name: 'VerticalDivider' }
</script>